// Colors
$grey-dark:                                             #33312d;
$grey:                                                  #848383;
$grey-light:                                            #dfe0db;
$grey-lighter:                                          #eae9e4;
$white:                                                 #fff;
$red:                                                   #b3302e;

$brand-primary:                                         #108480;
$brand-primary-dark:                                    #00413d;
$brand-primary-light:                                   #83cdc8;
$brand-secondary:                                       #ffdf00;
$brand-secondary-dark:                                  #9d8300;

//Students site colours

$uow-blue:                                              #4b92db;
$uow-blue-light:                                        #a5c9ed;
$uow-blue-dark:                                         #25496d;

//UOW brand colors
$uow-burgundy:                                          #6a1a41;
$uow-grey:                                              #7d9aaa;
$uow-turqoise:                                          #00b2a9;
$uow-orange:                                            #d47b22;
$uow-magenta:                                           #d10074;
$uow-magenta-masthead:                                  #AA0C62;
$uow-magenta-med:                                       #B90769;
$uow-yellow:                                            #e4d700;
$uow-red:                                               #f7403a;
$uow-purple:                                            #8c6cd0;
$uow-blue:                                              #4b92db;
$uow-green:                                             #34b233;

// Scaffolding
$body-bg:                                               #f3f4ef;

// Sidebar & Footer
$sidebar-bg-color:                                      $white;
$sidebar-border-color:                                  $brand-primary;
$sidebar-icon-bg-color:									$brand-primary;


// Navigation
$nav-border-color:                                      $grey-lighter;
$nav-hover-bg-color:                                    $brand-secondary;
$nav-text-color:                                        $grey-dark;

// Grid
$grid-item-bg-color:                                    $grey-light;

// Code
$code-title-bg-color:                                   #f3f4ef;
$code-title-text-color:                                 $grey-dark;
