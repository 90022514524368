@import "../../resources/scss/bootstrap/variables.scss";
@import "../../resources/scss/bootstrap/mixins.scss";
@import "utilities.scss";
@import "variables.scss";
@import "type.scss";
body {
    padding-bottom: 90px;
}

// Sidebar
.gel-sidebar {
    &__logo {
        display: block;
        margin: 0 0 20px 0;
        width: 80%;
    }
    &__nav {
        margin: 0;
        padding-left: 0;
        width: 100%;
        >li {
            @include font-size(1.6);
            @include font-karla-bold();
            border-top: 1px solid $nav-border-color;
            margin: 0;
            padding-bottom: 5px;
            padding-top: 5px;
            text-transform: uppercase;
            color: $text-color;
            &:before {
                border-left-color: $nav-text-color;
                left: auto;
                right: 20px;
                top: 22px;
                z-index: 9999;
            }
            &:last-of-type {
                border-bottom: 1px solid $nav-border-color;
            }
            span {
                color: $nav-text-color;
                cursor: pointer;
                display: inline-block;
                padding-bottom: 5px;
                padding-left: 10px;
                padding-right: 20px;
                padding-top: 7px;
                width: 100%;
            }
            &:hover {
                span {
                    background-color: $nav-hover-bg-color;
                }
            }
            &.js-open {
                &:before {
                    border-color: black transparent transparent transparent;
                    border-width: 4px 3.5px 0 3.5px;
                }
                &:hover {
                    &:before {
                        border-top-color: black;
                    }
                }
            }
        }
        &-dropdown {
            padding-bottom: 0px;
            padding-left: 10px;
            padding-top: 5px;
            li {
                margin: 0;
                padding-bottom: 1px;
                padding-left: 0;
                padding-right: 0;
                padding-top: 1px;
                &:before {
                    display: none;
                }
                a {
                    @include font-karla-bold();
                    @include font-size(1.6);
                    border-bottom: none;
                    display: inline-block;
                    line-height: 130%;
                    padding: 3px 10px;
                    text-decoration: none;
                    color: $nav-text-color;
                    &:hover {
                        background-color: $brand-secondary;
                        color: $grey-dark;
                    }
                    &.js-current {
                        background-color: $brand-secondary;
                        color: black;
                    }
                }
            }
            &.js-open {
                display: block;
            }
        }
    }
    &__arrow {
        @include transition(opacity 0.5s, visibility 1s);
        border: solid 2px $sidebar-border-color;
        color: $white;
        font-size: 17px;
        height: 80px;
        position: fixed;
        left: -10px;
        text-align: center;
        top: 40%;
        width: 60px;
        background-color: $sidebar-icon-bg-color;
        z-index: 1;
        i {
            display: inline-block;
            margin-top: 10px;
        }
    }
    // Switch HTML
    &__switch {
        margin-left: 65px;
        margin-top: 20px;
        text-align: center;
        width: 80px;
        &:focus {
            outline: 1px solid blue;
        }
        &-checkbox {
            position: absolute;
            margin-left: -9999px;
            visibility: hidden;
        }
        &-label {
            background-color: #dddddd;
            border-radius: 60px;
            cursor: pointer;
            display: inline-block;
            height: 24px;
            padding: 2px;
            position: relative;
            width: 50px;
            &:before,
            &:after {
                content: "";
                bottom: 1px;
                display: block;
                left: 1px;
                position: absolute;
                top: 1px;
            }
            &:before {
                @include transition(background 0.4s);
                background-color: #f1f1f1;
                border-radius: 60px;
                right: 1px;
            }
            &:after {
                @include transition(margin 0.4s);
                background-color: #fff;
                border-radius: 100%;
                box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
                width: 25px;
            }
            &--js-checked {
                &:before {
                    background-color: #14a5a1;
                }
                &:after {
                    margin-left: 25px;
                }
            }
            span {
                padding-top: 30px;
                width: 100px;
                display: inline-block;
                margin-left: -25px;
            }
        }
    }
}

#link-list>li {
    text-transform: uppercase;
    span {
        @include font-karla-bold;
        @include font-size (1.8);
    }
}

// Footer
.gel-footer {
    background-color: $sidebar-bg-color;
    border-top: solid 2px $sidebar-border-color;
    bottom: 0;
    font-size: 12px;
    // height: 50px;
    left: 0;
    line-height: 10px;
    position: fixed;
    width: 100%;
    z-index: 1000;
    @media (min-width: $screen-sm-min) {
        // height: 30px;
        // line-height: 30px;
    }
    &__copyright {
        @include make-xs-column(12);
        @include make-sm-column(3);
        text-align: center;
        line-height: 30px;
        @media (min-width: $screen-sm-min) {
            text-align: left;
        }
    }
    &__contact {
        @include make-xs-column(12);
        @include make-sm-column(9);
        text-align: center;
        margin-bottom: 10px;
        @media (min-width: $screen-sm-min) {
            text-align: right;
        }
        a {
            border-bottom: 1px solid $grey-dark;
            &:hover,
            &:focus {
                background-color: $grey-dark;
                color: $body-bg;
                text-decoration: underline;
            }
        }

        ul {
            display: inline-block;
            text-align: left;
            margin-top:0;
            margin-bottom: 0;
            margin-right: 10px;
            padding-left: 0;
            li {
                font-size: 12px;
                &:before {
                    display: none;
                }
                a {
                    @include font-karla-regular()
                }
            }
        }
    }
}

// Grid
.grid {
    .grid-item {
        font-size: 12px;
        background-color: $grid-item-bg-color;
        height: 70px;
        line-height: 70px;
        margin-bottom: 1px;
        text-align: center;
        vertical-align: middle;
        width: 100%;
    }
    .col-xs-12 {
        .grid-item {
            &:before {
                content: ".col-xs-12";
                @media (min-width: $screen-sm-min) {
                    content: ".col-sm-12";
                }
                @media (min-width: $screen-md-min) {
                    content: ".col-md-12";
                }
                @media (min-width: $screen-lg-min) {
                    content: ".col-lg-12";
                }
            }
        }
    }
    .col-xs-6 {
        .grid-item {
            &:before {
                content: ".col-xs-6";
                @media (min-width: $screen-sm-min) {
                    content: ".col-sm-6";
                }
                @media (min-width: $screen-md-min) {
                    content: ".col-md-6";
                }
                @media (min-width: $screen-lg-min) {
                    content: ".col-lg-6";
                }
            }
        }
    }
    .col-sm-4 {
        .grid-item {
            &:before {
                @media (min-width: $screen-sm-min) {
                    content: ".col-sm-4";
                }
                @media (min-width: $screen-md-min) {
                    content: ".col-md-4";
                }
                @media (min-width: $screen-lg-min) {
                    content: ".col-lg-4";
                }
            }
        }
    }
    .col-sm-3 {
        .grid-item {
            &:before {
                @media (min-width: $screen-sm-min) {
                    content: ".col-sm-3";
                }
                @media (min-width: $screen-md-min) {
                    content: ".col-md-3";
                }
                @media (min-width: $screen-lg-min) {
                    content: ".col-lg-3";
                }
            }
        }
    }
    .col-sm-2 {
        .grid-item {
            &:before {
                @media (min-width: $screen-sm-min) {
                    content: ".col-sm-2";
                }
                @media (min-width: $screen-md-min) {
                    content: ".col-md-2";
                }
                @media (min-width: $screen-lg-min) {
                    content: ".col-lg-2";
                }
            }
        }
    }
}

// Type font
.type-font {
    bottom: -20px;
    border-bottom: 1px solid $text-color;
    display: inline-block;
    position: relative;
}

// Colour pallete
.colour-pallete {
    display: inline-block;
    margin: 20px 30px;
    &__colour {
        border-radius: 50%;
        display: inline-block;
        height: 170px;
        width: 170px;
        &--primary {
            background-color: $brand-primary;
        }
        &--primary-dark {
            background-color: $brand-primary-dark;
        }
        &--primary-light {
            background-color: $brand-primary-light;
        }
        &--uow-magenta {
            background-color: $uow-magenta;
        }
        &--uow-magenta-masthead {
            background-color: $uow-magenta-masthead;
        }
        &--secondary {
            background-color: $brand-secondary;
        }
        &--secondary-dark {
            background-color: $brand-secondary-dark;
        }
        // Students section colours
        &--uow-blue-dark {
            background-color: $uow-blue-dark;
        }
        &--uow-blue-light {
            background-color: $uow-blue-light;
        }
        &--grey {
            background-color: $grey;
        }
        &--grey-dark {
            background-color: $grey-dark;
        }
        &--grey-light {
            background-color: $grey-light;
        }
        &--grey-lighter {
            background-color: $grey-lighter;
        }
        &--background {
            background-color: $body-bg;
            border: 1px solid $grey;
        }
        &--white {
            background-color: $white;
        }
        &--red {
            background-color: $red;
        }
        //UOW BRAND COLOURS
        &--uow-burgundy {
            background-color: $uow-burgundy;
        }
        &--uow-grey {
            background-color: $uow-grey;
        }
        &--uow-turqoise {
            background-color: $uow-turqoise;
        }
        &--uow-orange {
            background-color: $uow-orange;
        }
        &--uow-magenta {
            background-color: $uow-magenta;
        }
        &--uow-green {
            background-color: $uow-green;
        }
        &--uow-purple {
            background-color: $uow-purple;
        }
        &--uow-red {
            background-color: $uow-red;
        }
        &--uow-yellow {
            background-color: $uow-yellow;
        }
        &--uow-blue {
            background-color: $uow-blue;
        }
    }
    &__text {
        margin-top: 10px;
        dt {
            clear: left;
            float: left;
            width: 80px;
        }
        dd {
            float: left;
        }
    }
}

// X-rayHTML plugin
.xrayhtml {
    border-radius: .3em;
    width: 100%;
}

.xrayhtml .xraytitle {
    text-transform: uppercase;
    letter-spacing: 1px;
    font: .75em sans-serif;
    color: $code-title-text-color;
    display: none;
    background-color: $code-title-bg-color;
    border-radius: 3px;
    position: relative;
    top: -2.266666667em;
    /* 26px */
    padding-left: .1em;
    padding-right: .1em;
    z-index: 3;
    margin: 0;
}

.xrayhtml.method-flip:before {
    background-color: rgba(255, 255, 255, .6);
}

.xrayhtml .source-panel {
    box-sizing: border-box;
    display: none;
    float: left;
    margin-left: 30px;
    margin-bottom: 30px;
    margin-top: 30px;
    tab-size: 2;
    width: 90%;
    html.show-html & {
        display: block;
    }
}

.xrayhtml .source-panel pre {
    margin: 0;
}

.xrayhtml .source-panel code {
    white-space: pre-wrap;
}

.xrayhtml.method-flip .source-panel {
    margin-top: 0;
    border-radius: 0.3em;
}

.xrayhtml.method-inline .source-panel {
    //margin: 2em -1em -2em -1em !important; /* Prism style override. */
    border: 1px solid rgba(0, 0, 0, .1);
    border-radius: 0 0 .3em .3em;
}

.xrayhtml pre {
    border-top: 1px solid rgba(0, 0, 0, .1);
    padding: 16px;
    margin: 0 !important;
    /* Prism style override. */
    border-radius: 0 0 .3em .3em;
}

.xrayhtml code {
    white-space: pre-wrap !important;
    /* Prism style override. */
}

.xrayhtml.antipattern {
    border-color: #C9282D;
}

.xrayhtml.antipattern .xraytitle {
    color: #d75e72;
    font-weight: 700;
}


/* http://prismjs.com/download.html?themes=prism&languages=markup+clike+javascript */


/**
 * prism.js default theme for JavaScript, CSS and HTML
 * Based on dabblet (http://dabblet.com)
 * $author Lea Verou
 */

code[class*="language-"],
pre[class*="language-"] {
    color: black;
    background: none;
    text-shadow: 0 1px white;
    font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
    text-align: left;
    white-space: pre;
    word-spacing: normal;
    word-break: normal;
    word-wrap: normal;
    line-height: 1.5;
    -moz-tab-size: 4;
    -o-tab-size: 4;
    tab-size: 4;
    -webkit-hyphens: none;
    -moz-hyphens: none;
    -ms-hyphens: none;
    hyphens: none;
}

pre[class*="language-"]::-moz-selection,
pre[class*="language-"] ::-moz-selection,
code[class*="language-"]::-moz-selection,
code[class*="language-"] ::-moz-selection {
    text-shadow: none;
    background: #b3d4fc;
}

pre[class*="language-"]::selection,
pre[class*="language-"] ::selection,
code[class*="language-"]::selection,
code[class*="language-"] ::selection {
    text-shadow: none;
    background: #b3d4fc;
}

@media print {
    code[class*="language-"],
    pre[class*="language-"] {
        text-shadow: none;
    }
}


/* Code blocks */

pre[class*="language-"] {
    padding: 1em;
    margin: .5em 0;
    overflow: auto;
}

:not(pre)>code[class*="language-"],
pre[class*="language-"] {
    background: #f5f2f0;
}


/* Inline code */

:not(pre)>code[class*="language-"] {
    padding: .1em;
    border-radius: .3em;
    white-space: normal;
}

.token.comment,
.token.prolog,
.token.doctype,
.token.cdata {
    color: slategray;
}

.token.punctuation {
    color: #999;
}

.namespace {
    opacity: .7;
}

.token.property,
.token.tag,
.token.boolean,
.token.number,
.token.constant,
.token.symbol,
.token.deleted {
    color: #905;
}

.token.selector,
.token.attr-name,
.token.string,
.token.char,
.token.builtin,
.token.inserted {
    color: #690;
}

.token.operator,
.token.entity,
.token.url,
.language-css .token.string,
.style .token.string {
    color: #a67f59;
    background: hsla(0, 0%, 100%, .5);
}

.token.atrule,
.token.attr-value,
.token.keyword {
    color: #07a;
}

.token.function {
    color: #DD4A68;
}

.token.regex,
.token.important,
.token.variable {
    color: #e90;
}

.token.important,
.token.bold {
    font-weight: bold;
}

.token.italic {
    font-style: italic;
}

.token.entity {
    cursor: help;
}

.accordion-image {
    background-image: url('/resources/images/test/scholarships/Word-Map-for-Spot-UV_Black-outlines.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.search-listing {
    border-bottom: solid #000000;
    border-width: thin;
    margin-top: 0.5cm;
    margin-bottom: 0.5cm;
}

button.open-nav-btn {
    font-size: 1.5rem;
    margin: 10px;
}

.slide-content {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    background-color: $white;
    color: $grey-dark;
    overflow-x: hidden;
    transition: width 0.5s;
    padding-top: 10px;
    visibility: hidden;
}

.slide-content a {
    padding: 8px 8px 8px 20px;
    text-decoration: none;
    font-size: 25px;
    display: block;
}

.slide-content a:focus,
.slide-content button:focus {
    outline: 2px solid orange;
}

main a:focus,
.open-nav-btn:focus {
    outline: 2px solid blue;
    outline-offset: 5px;
}

.close-btn {
    font-size: 25px;
    color: $grey-dark;
    background-color: transparent;
    border: none;
}

.slide-content a:hover,
.slide-content a:focus {
    text-decoration: underline;
}

.slide-content .close-btn {
    position: absolute;
    top: 0;
    right: 10px;
    font-size: 32px;
    margin-left: 50px;
}

.visible {
    visibility: visible;
}

#slide-nav.active {
    width: 250px;
}

main {
    padding: 0 10%;
    li {
        margin-bottom: 10px;
    }
}