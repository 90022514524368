/* -----------------------------------
 * LESS Useful Utilities
 */

/* REM Font size with Pixel fallback */
@mixin font-size($sizeValue) {
    $remValue: $sizeValue;
    $pxValue: ($sizeValue * 10);
    font-size: #{$pxValue}px;
    font-size: #{$remValue}rem;
}

/* Transition */
@mixin transition($arguments...) {
    -webkit-transition: $arguments;
    -moz-transition: $arguments;
    -o-transition: $arguments;
    -ms-transition: $arguments;
    transition: $arguments;
}

/* Remove bootstrap gutter when showing component source */
.no-gutter {
    margin-left: 0;
    margin-right: 0;

    .source-panel {
        margin-left: 0 !important;
        width: 100% !important;
    }

    .snippet > [class*="col-"] {
        padding-left: 0;
        padding-right: 0;
    }
}
