/* -----------------------------------
 * LESS Useful Utilities
 */
/* REM Font size with Pixel fallback */
/* Transition */
/* Remove bootstrap gutter when showing component source */
.no-gutter {
  margin-left: 0;
  margin-right: 0;
}
.no-gutter .source-panel {
  margin-left: 0 !important;
  width: 100% !important;
}
.no-gutter .snippet > [class*=col-] {
  padding-left: 0;
  padding-right: 0;
}

@font-face {
  font-family: "Futura W01 Bold";
  src: url("/resources/fonts/3b3d00eb-6d89-47ec-83ee-48276a47ad94.eot?#iefix");
  src: url("/resources/fonts/3b3d00eb-6d89-47ec-83ee-48276a47ad94.eot?#iefix") format("embedded-opentype"), url("/resources/fonts/04a4ca93-1306-487e-a524-e798404c93a7.woff2") format("woff2"), url("/resources/fonts/2a65cbfc-5071-4b4d-bfc2-e052bb091ee2.woff") format("woff"), url("/resources/fonts/812f781d-aa66-47a1-b6a2-7941effc1527.ttf") format("truetype"), url("/resources/fonts/2a371030-ff67-40ac-8307-082c1fccf151.svg#2a371030-ff67-40ac-8307-082c1fccf151") format("svg");
}
@font-face {
  font-family: "KarlaRegular";
  src: url("/resources/fonts/KarlaRegular.eot");
  src: url("/resources/fonts/KarlaRegular.eot") format("embedded-opentype"), url("/resources/fonts/KarlaRegular.woff2") format("woff2"), url("/resources/fonts/KarlaRegular.woff") format("woff"), url("/resources/fonts/KarlaRegular.ttf") format("truetype"), url("/resources/fonts/KarlaRegular.svg#KarlaRegular") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "KarlaBold";
  src: url("/resources/fonts/KarlaBold.eot");
  src: url("/resources/fonts/KarlaBold.eot") format("embedded-opentype"), url("/resources/fonts/KarlaBold.woff2") format("woff2"), url("/resources/fonts/KarlaBold.woff") format("woff"), url("/resources/fonts/KarlaBold.ttf") format("truetype"), url("/resources/fonts/KarlaBold.svg#KarlaBold") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "KarlaBoldItalic";
  src: url("/resources/fonts/KarlaBoldItalic.eot");
  src: url("/resources/fonts/KarlaBoldItalic.eot") format("embedded-opentype"), url("/resources/fonts/KarlaBoldItalic.woff2") format("woff2"), url("/resources/fonts/KarlaBoldItalic.woff") format("woff"), url("/resources/fonts/KarlaBoldItalic.ttf") format("truetype"), url("/resources/fonts/KarlaBoldItalic.svg#KarlaBoldItalic") format("svg");
  font-weight: normal;
  font-style: normal;
}
body {
  padding-bottom: 90px;
}

.gel-sidebar__logo {
  display: block;
  margin: 0 0 20px 0;
  width: 80%;
}
.gel-sidebar__nav {
  margin: 0;
  padding-left: 0;
  width: 100%;
}
.gel-sidebar__nav > li {
  font-size: 16px;
  font-size: 1.6rem;
  font-family: "KarlaBold";
  border-top: 1px solid #eae9e4;
  margin: 0;
  padding-bottom: 5px;
  padding-top: 5px;
  text-transform: uppercase;
  color: #333333;
}
.gel-sidebar__nav > li:before {
  border-left-color: #33312d;
  left: auto;
  right: 20px;
  top: 22px;
  z-index: 9999;
}
.gel-sidebar__nav > li:last-of-type {
  border-bottom: 1px solid #eae9e4;
}
.gel-sidebar__nav > li span {
  color: #33312d;
  cursor: pointer;
  display: inline-block;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 20px;
  padding-top: 7px;
  width: 100%;
}
.gel-sidebar__nav > li:hover span {
  background-color: #ffdf00;
}
.gel-sidebar__nav > li.js-open:before {
  border-color: black transparent transparent transparent;
  border-width: 4px 3.5px 0 3.5px;
}
.gel-sidebar__nav > li.js-open:hover:before {
  border-top-color: black;
}
.gel-sidebar__nav-dropdown {
  padding-bottom: 0px;
  padding-left: 10px;
  padding-top: 5px;
}
.gel-sidebar__nav-dropdown li {
  margin: 0;
  padding-bottom: 1px;
  padding-left: 0;
  padding-right: 0;
  padding-top: 1px;
}
.gel-sidebar__nav-dropdown li:before {
  display: none;
}
.gel-sidebar__nav-dropdown li a {
  font-family: "KarlaBold";
  font-size: 16px;
  font-size: 1.6rem;
  border-bottom: none;
  display: inline-block;
  line-height: 130%;
  padding: 3px 10px;
  text-decoration: none;
  color: #33312d;
}
.gel-sidebar__nav-dropdown li a:hover {
  background-color: #ffdf00;
  color: #33312d;
}
.gel-sidebar__nav-dropdown li a.js-current {
  background-color: #ffdf00;
  color: black;
}
.gel-sidebar__nav-dropdown.js-open {
  display: block;
}
.gel-sidebar__arrow {
  -webkit-transition: opacity 0.5s, visibility 1s;
  -moz-transition: opacity 0.5s, visibility 1s;
  -o-transition: opacity 0.5s, visibility 1s;
  -ms-transition: opacity 0.5s, visibility 1s;
  transition: opacity 0.5s, visibility 1s;
  border: solid 2px #108480;
  color: #fff;
  font-size: 17px;
  height: 80px;
  position: fixed;
  left: -10px;
  text-align: center;
  top: 40%;
  width: 60px;
  background-color: #108480;
  z-index: 1;
}
.gel-sidebar__arrow i {
  display: inline-block;
  margin-top: 10px;
}
.gel-sidebar__switch {
  margin-left: 65px;
  margin-top: 20px;
  text-align: center;
  width: 80px;
}
.gel-sidebar__switch:focus {
  outline: 1px solid blue;
}
.gel-sidebar__switch-checkbox {
  position: absolute;
  margin-left: -9999px;
  visibility: hidden;
}
.gel-sidebar__switch-label {
  background-color: #dddddd;
  border-radius: 60px;
  cursor: pointer;
  display: inline-block;
  height: 24px;
  padding: 2px;
  position: relative;
  width: 50px;
}
.gel-sidebar__switch-label:before, .gel-sidebar__switch-label:after {
  content: "";
  bottom: 1px;
  display: block;
  left: 1px;
  position: absolute;
  top: 1px;
}
.gel-sidebar__switch-label:before {
  -webkit-transition: background 0.4s;
  -moz-transition: background 0.4s;
  -o-transition: background 0.4s;
  -ms-transition: background 0.4s;
  transition: background 0.4s;
  background-color: #f1f1f1;
  border-radius: 60px;
  right: 1px;
}
.gel-sidebar__switch-label:after {
  -webkit-transition: margin 0.4s;
  -moz-transition: margin 0.4s;
  -o-transition: margin 0.4s;
  -ms-transition: margin 0.4s;
  transition: margin 0.4s;
  background-color: #fff;
  border-radius: 100%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  width: 25px;
}
.gel-sidebar__switch-label--js-checked:before {
  background-color: #14a5a1;
}
.gel-sidebar__switch-label--js-checked:after {
  margin-left: 25px;
}
.gel-sidebar__switch-label span {
  padding-top: 30px;
  width: 100px;
  display: inline-block;
  margin-left: -25px;
}

#link-list > li {
  text-transform: uppercase;
}
#link-list > li span {
  font-family: "KarlaBold";
  font-size: 18px;
  font-size: 1.8rem;
}

.gel-footer {
  background-color: #fff;
  border-top: solid 2px #108480;
  bottom: 0;
  font-size: 12px;
  left: 0;
  line-height: 10px;
  position: fixed;
  width: 100%;
  z-index: 1000;
}
.gel-footer__copyright {
  position: relative;
  float: left;
  width: 100%;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
  text-align: center;
  line-height: 30px;
}
@media (min-width: 768px) {
  .gel-footer__copyright {
    float: left;
    width: 25%;
  }
}
@media (min-width: 768px) {
  .gel-footer__copyright {
    text-align: left;
  }
}
.gel-footer__contact {
  position: relative;
  float: left;
  width: 100%;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
  text-align: center;
  margin-bottom: 10px;
}
@media (min-width: 768px) {
  .gel-footer__contact {
    float: left;
    width: 75%;
  }
}
@media (min-width: 768px) {
  .gel-footer__contact {
    text-align: right;
  }
}
.gel-footer__contact a {
  border-bottom: 1px solid #33312d;
}
.gel-footer__contact a:hover, .gel-footer__contact a:focus {
  background-color: #33312d;
  color: #f3f4ef;
  text-decoration: underline;
}
.gel-footer__contact ul {
  display: inline-block;
  text-align: left;
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 10px;
  padding-left: 0;
}
.gel-footer__contact ul li {
  font-size: 12px;
}
.gel-footer__contact ul li:before {
  display: none;
}
.gel-footer__contact ul li a {
  font-family: "KarlaRegular";
}

.grid .grid-item {
  font-size: 12px;
  background-color: #dfe0db;
  height: 70px;
  line-height: 70px;
  margin-bottom: 1px;
  text-align: center;
  vertical-align: middle;
  width: 100%;
}
.grid .col-xs-12 .grid-item:before {
  content: ".col-xs-12";
}
@media (min-width: 768px) {
  .grid .col-xs-12 .grid-item:before {
    content: ".col-sm-12";
  }
}
@media (min-width: 992px) {
  .grid .col-xs-12 .grid-item:before {
    content: ".col-md-12";
  }
}
@media (min-width: 1200px) {
  .grid .col-xs-12 .grid-item:before {
    content: ".col-lg-12";
  }
}
.grid .col-xs-6 .grid-item:before {
  content: ".col-xs-6";
}
@media (min-width: 768px) {
  .grid .col-xs-6 .grid-item:before {
    content: ".col-sm-6";
  }
}
@media (min-width: 992px) {
  .grid .col-xs-6 .grid-item:before {
    content: ".col-md-6";
  }
}
@media (min-width: 1200px) {
  .grid .col-xs-6 .grid-item:before {
    content: ".col-lg-6";
  }
}
@media (min-width: 768px) {
  .grid .col-sm-4 .grid-item:before {
    content: ".col-sm-4";
  }
}
@media (min-width: 992px) {
  .grid .col-sm-4 .grid-item:before {
    content: ".col-md-4";
  }
}
@media (min-width: 1200px) {
  .grid .col-sm-4 .grid-item:before {
    content: ".col-lg-4";
  }
}
@media (min-width: 768px) {
  .grid .col-sm-3 .grid-item:before {
    content: ".col-sm-3";
  }
}
@media (min-width: 992px) {
  .grid .col-sm-3 .grid-item:before {
    content: ".col-md-3";
  }
}
@media (min-width: 1200px) {
  .grid .col-sm-3 .grid-item:before {
    content: ".col-lg-3";
  }
}
@media (min-width: 768px) {
  .grid .col-sm-2 .grid-item:before {
    content: ".col-sm-2";
  }
}
@media (min-width: 992px) {
  .grid .col-sm-2 .grid-item:before {
    content: ".col-md-2";
  }
}
@media (min-width: 1200px) {
  .grid .col-sm-2 .grid-item:before {
    content: ".col-lg-2";
  }
}

.type-font {
  bottom: -20px;
  border-bottom: 1px solid #333333;
  display: inline-block;
  position: relative;
}

.colour-pallete {
  display: inline-block;
  margin: 20px 30px;
}
.colour-pallete__colour {
  border-radius: 50%;
  display: inline-block;
  height: 170px;
  width: 170px;
}
.colour-pallete__colour--primary {
  background-color: #108480;
}
.colour-pallete__colour--primary-dark {
  background-color: #00413d;
}
.colour-pallete__colour--primary-light {
  background-color: #83cdc8;
}
.colour-pallete__colour--uow-magenta {
  background-color: #d10074;
}
.colour-pallete__colour--uow-magenta-masthead {
  background-color: #AA0C62;
}
.colour-pallete__colour--secondary {
  background-color: #ffdf00;
}
.colour-pallete__colour--secondary-dark {
  background-color: #9d8300;
}
.colour-pallete__colour--uow-blue-dark {
  background-color: #25496d;
}
.colour-pallete__colour--uow-blue-light {
  background-color: #a5c9ed;
}
.colour-pallete__colour--grey {
  background-color: #848383;
}
.colour-pallete__colour--grey-dark {
  background-color: #33312d;
}
.colour-pallete__colour--grey-light {
  background-color: #dfe0db;
}
.colour-pallete__colour--grey-lighter {
  background-color: #eae9e4;
}
.colour-pallete__colour--background {
  background-color: #f3f4ef;
  border: 1px solid #848383;
}
.colour-pallete__colour--white {
  background-color: #fff;
}
.colour-pallete__colour--red {
  background-color: #b3302e;
}
.colour-pallete__colour--uow-burgundy {
  background-color: #6a1a41;
}
.colour-pallete__colour--uow-grey {
  background-color: #7d9aaa;
}
.colour-pallete__colour--uow-turqoise {
  background-color: #00b2a9;
}
.colour-pallete__colour--uow-orange {
  background-color: #d47b22;
}
.colour-pallete__colour--uow-magenta {
  background-color: #d10074;
}
.colour-pallete__colour--uow-green {
  background-color: #34b233;
}
.colour-pallete__colour--uow-purple {
  background-color: #8c6cd0;
}
.colour-pallete__colour--uow-red {
  background-color: #f7403a;
}
.colour-pallete__colour--uow-yellow {
  background-color: #e4d700;
}
.colour-pallete__colour--uow-blue {
  background-color: #4b92db;
}
.colour-pallete__text {
  margin-top: 10px;
}
.colour-pallete__text dt {
  clear: left;
  float: left;
  width: 80px;
}
.colour-pallete__text dd {
  float: left;
}

.xrayhtml {
  border-radius: 0.3em;
  width: 100%;
}

.xrayhtml .xraytitle {
  text-transform: uppercase;
  letter-spacing: 1px;
  font: 0.75em sans-serif;
  color: #33312d;
  display: none;
  background-color: #f3f4ef;
  border-radius: 3px;
  position: relative;
  top: -2.266666667em;
  /* 26px */
  padding-left: 0.1em;
  padding-right: 0.1em;
  z-index: 3;
  margin: 0;
}

.xrayhtml.method-flip:before {
  background-color: rgba(255, 255, 255, 0.6);
}

.xrayhtml .source-panel {
  box-sizing: border-box;
  display: none;
  float: left;
  margin-left: 30px;
  margin-bottom: 30px;
  margin-top: 30px;
  tab-size: 2;
  width: 90%;
}
html.show-html .xrayhtml .source-panel {
  display: block;
}

.xrayhtml .source-panel pre {
  margin: 0;
}

.xrayhtml .source-panel code {
  white-space: pre-wrap;
}

.xrayhtml.method-flip .source-panel {
  margin-top: 0;
  border-radius: 0.3em;
}

.xrayhtml.method-inline .source-panel {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0 0 0.3em 0.3em;
}

.xrayhtml pre {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding: 16px;
  margin: 0 !important;
  /* Prism style override. */
  border-radius: 0 0 0.3em 0.3em;
}

.xrayhtml code {
  white-space: pre-wrap !important;
  /* Prism style override. */
}

.xrayhtml.antipattern {
  border-color: #C9282D;
}

.xrayhtml.antipattern .xraytitle {
  color: #d75e72;
  font-weight: 700;
}

/* http://prismjs.com/download.html?themes=prism&languages=markup+clike+javascript */
/**
 * prism.js default theme for JavaScript, CSS and HTML
 * Based on dabblet (http://dabblet.com)
 * $author Lea Verou
 */
code[class*=language-],
pre[class*=language-] {
  color: black;
  background: none;
  text-shadow: 0 1px white;
  font-family: Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace;
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  word-wrap: normal;
  line-height: 1.5;
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}

pre[class*=language-]::-moz-selection,
pre[class*=language-] ::-moz-selection,
code[class*=language-]::-moz-selection,
code[class*=language-] ::-moz-selection {
  text-shadow: none;
  background: #b3d4fc;
}

pre[class*=language-]::selection,
pre[class*=language-] ::selection,
code[class*=language-]::selection,
code[class*=language-] ::selection {
  text-shadow: none;
  background: #b3d4fc;
}

@media print {
  code[class*=language-],
  pre[class*=language-] {
    text-shadow: none;
  }
}
/* Code blocks */
pre[class*=language-] {
  padding: 1em;
  margin: 0.5em 0;
  overflow: auto;
}

:not(pre) > code[class*=language-],
pre[class*=language-] {
  background: #f5f2f0;
}

/* Inline code */
:not(pre) > code[class*=language-] {
  padding: 0.1em;
  border-radius: 0.3em;
  white-space: normal;
}

.token.comment,
.token.prolog,
.token.doctype,
.token.cdata {
  color: slategray;
}

.token.punctuation {
  color: #999;
}

.namespace {
  opacity: 0.7;
}

.token.property,
.token.tag,
.token.boolean,
.token.number,
.token.constant,
.token.symbol,
.token.deleted {
  color: #905;
}

.token.selector,
.token.attr-name,
.token.string,
.token.char,
.token.builtin,
.token.inserted {
  color: #690;
}

.token.operator,
.token.entity,
.token.url,
.language-css .token.string,
.style .token.string {
  color: #a67f59;
  background: hsla(0, 0%, 100%, 0.5);
}

.token.atrule,
.token.attr-value,
.token.keyword {
  color: #07a;
}

.token.function {
  color: #DD4A68;
}

.token.regex,
.token.important,
.token.variable {
  color: #e90;
}

.token.important,
.token.bold {
  font-weight: bold;
}

.token.italic {
  font-style: italic;
}

.token.entity {
  cursor: help;
}

.accordion-image {
  background-image: url("/resources/images/test/scholarships/Word-Map-for-Spot-UV_Black-outlines.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.search-listing {
  border-bottom: solid #000000;
  border-width: thin;
  margin-top: 0.5cm;
  margin-bottom: 0.5cm;
}

button.open-nav-btn {
  font-size: 1.5rem;
  margin: 10px;
}

.slide-content {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  background-color: #fff;
  color: #33312d;
  overflow-x: hidden;
  transition: width 0.5s;
  padding-top: 10px;
  visibility: hidden;
}

.slide-content a {
  padding: 8px 8px 8px 20px;
  text-decoration: none;
  font-size: 25px;
  display: block;
}

.slide-content a:focus,
.slide-content button:focus {
  outline: 2px solid orange;
}

main a:focus,
.open-nav-btn:focus {
  outline: 2px solid blue;
  outline-offset: 5px;
}

.close-btn {
  font-size: 25px;
  color: #33312d;
  background-color: transparent;
  border: none;
}

.slide-content a:hover,
.slide-content a:focus {
  text-decoration: underline;
}

.slide-content .close-btn {
  position: absolute;
  top: 0;
  right: 10px;
  font-size: 32px;
  margin-left: 50px;
}

.visible {
  visibility: visible;
}

#slide-nav.active {
  width: 250px;
}

main {
  padding: 0 10%;
}
main li {
  margin-bottom: 10px;
}