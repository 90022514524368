@font-face{
    font-family:"Futura W01 Bold";
    src:url("/resources/fonts/3b3d00eb-6d89-47ec-83ee-48276a47ad94.eot?#iefix");
    src:url("/resources/fonts/3b3d00eb-6d89-47ec-83ee-48276a47ad94.eot?#iefix") format("embedded-opentype"),
    url("/resources/fonts/04a4ca93-1306-487e-a524-e798404c93a7.woff2") format("woff2"),
    url("/resources/fonts/2a65cbfc-5071-4b4d-bfc2-e052bb091ee2.woff") format("woff"),
    url("/resources/fonts/812f781d-aa66-47a1-b6a2-7941effc1527.ttf") format("truetype"),
    url("/resources/fonts/2a371030-ff67-40ac-8307-082c1fccf151.svg#2a371030-ff67-40ac-8307-082c1fccf151") format("svg");
}

@font-face {
    font-family: "KarlaRegular";
    src: url("/resources/fonts/KarlaRegular.eot");
    src: url("/resources/fonts/KarlaRegular.eot") format("embedded-opentype"),
    url("/resources/fonts/KarlaRegular.woff2") format("woff2"),
    url("/resources/fonts/KarlaRegular.woff") format("woff"),
    url("/resources/fonts/KarlaRegular.ttf") format("truetype"),
    url("/resources/fonts/KarlaRegular.svg#KarlaRegular") format("svg");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "KarlaBold";
    src: url("/resources/fonts/KarlaBold.eot");
    src: url("/resources/fonts/KarlaBold.eot") format("embedded-opentype"),
    url("/resources/fonts/KarlaBold.woff2") format("woff2"),
    url("/resources/fonts/KarlaBold.woff") format("woff"),
    url("/resources/fonts/KarlaBold.ttf") format("truetype"),
    url("/resources/fonts/KarlaBold.svg#KarlaBold") format("svg");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "KarlaBoldItalic";
    src: url("/resources/fonts/KarlaBoldItalic.eot");
    src: url("/resources/fonts/KarlaBoldItalic.eot") format("embedded-opentype"),
    url("/resources/fonts/KarlaBoldItalic.woff2") format("woff2"),
    url("/resources/fonts/KarlaBoldItalic.woff") format("woff"),
    url("/resources/fonts/KarlaBoldItalic.ttf") format("truetype"),
    url("/resources/fonts/KarlaBoldItalic.svg#KarlaBoldItalic") format("svg");
    font-weight: normal;
    font-style: normal;
}

@mixin font-karla-regular() {
    font-family: "KarlaRegular";
}

@mixin font-futura-bold() {
    font-family:"Futura W01 Bold";
}

@mixin font-karla-bold() {
    font-family: "KarlaBold";
}

@mixin font-karla-bold-italic() {
    font-family: "KarlaBoldItalic";
}
